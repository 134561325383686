import { IntlShape } from "react-intl";
import ConfigurationService from "../../../../services/ConfigurationService";
import NotificationsManager from "../../../ErrorHandling/Notifications/NotificationsManager";
import imageCompression from 'browser-image-compression';

/**
 * Formats a given byte number to a GB/MB/KB/bytes/byte string.
 *
 * @param bytes A byte number
 * @returns The formatted string
 */
const formatSizeUnits = (bytes: number): string => {
    const convertToString = (num: number): string => {
        const fixed = Math.round(num * 100) / 100;
        return fixed.toString();
    };

    if (bytes >= 1073741824) {
        return `${convertToString(bytes / 1073741824)} GB`;
    } else if (bytes >= 1048576) {
        return `${convertToString(bytes / 1048576)} MB`;
    } else if (bytes >= 1024) {
        return `${convertToString(bytes / 1024)} KB`;
    } else if (bytes > 1) {
        return `${bytes} + bytes`;
    } else if (bytes === 1) {
        return `${bytes} + bytes`;
    }
    return "0 bytes";
};

// handles files dropped in EventInputInformation
const handleFileDropped = async (acceptedFiles: File[], receipts: [File, string][],  intl: IntlShape) => {
    let newList = [] as [File, string][];
    const fileSizeLimitBytes = ConfigurationService.getClaimFormFileSizeLimit();

    for (const file of acceptedFiles) {
        try {
            let compressed = file.size < fileSizeLimitBytes ? file : await imageCompression(file, {maxSizeMB: 1});
            newList.push([compressed, URL.createObjectURL(compressed)]);
        }
        catch (ex) {
            console.log("error", ex)
        }
    }

    if (validateFilesDropped(newList, receipts, intl)) {
        return;
    }

    return newList;
};

// handles files dropped in ItemInputInformation
const handleReceiptDropped = async (acceptedFiles: File[], files: [File, string][], intl: IntlShape) => {
    let newList = [] as [File, string][];
    const fileSizeLimitBytes = ConfigurationService.getClaimFormFileSizeLimit();

    for (const file of acceptedFiles) {
        try {
            let compressed = file.size < fileSizeLimitBytes ? file : await imageCompression(file, {maxSizeMB: 1});
            newList.push([compressed, URL.createObjectURL(compressed)]);
        }
        catch (ex) {
            console.log("error", ex)
        }
    }

    if (validateFilesDropped(newList, files, intl)) {
        return;
    }

    return newList;
};

const validateFilesDropped = (newFiles: [File, string][], files: [File, string][], intl: IntlShape) => {
    if (newFiles.length + files.length > ConfigurationService.getClaimFormMaximumAmountOfFiles()) {
        NotificationsManager.error(
            intl.formatMessage({
                id:
                    "ClaimForm.IsExceedingTotalNumberOFFilesHandleDropped",
            })
        );
        return true;
    }
    else if (isExceedingTotalFileSize(newFiles, files)) {
        NotificationsManager.error(
            intl.formatMessage({
                id: "ClaimForm.isExceedingTotalFileSizeOfHandleFileDropped",
            })
        );
        return true;
    }

    return false;
}

 const isExceedingTotalFileSize = (newFiles: [File, string][], files: [File, string][]): boolean => {
    /** The total maximum file size that can be uploaded to the server */
	const fileSizeLimitBytes = ConfigurationService.getClaimsFormAllFilesSizeLimit();
    let isExceedingSizeLimit = false;
    let combinedFileSize = 0;
    newFiles.forEach(file => {
        combinedFileSize += file[0].size
    });
    files.forEach(file => {
        combinedFileSize += file[0].size
    });

    if  (combinedFileSize >= fileSizeLimitBytes) {
        isExceedingSizeLimit = true;
    }
    
    return isExceedingSizeLimit;
};

export {
	formatSizeUnits,
	handleFileDropped,
    handleReceiptDropped
};